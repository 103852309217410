import { containerless, bindable, bindingMode } from "aurelia-framework";
import { ReportingFrequency } from "models/ReportingFrequency";
import { ReportingTerm } from "models/ReportingTerm";
import { ReportingTerms } from "models/ReportingTerms";


@containerless
export class ReportingTermSelectorCustomElement {
    @bindable({ defaultBindingMode: bindingMode.oneWay }) reportingFrequency: ReportingFrequency; 
    @bindable({ defaultBindingMode: bindingMode.twoWay }) selectedReportingTerm: ReportingTerm;

    get reportingTerms() {
        const terms = this.getSelectedReportingTerms();

        if (terms)
            return terms;

        return [];
    }

    getSelectedReportingTerms() {
        var _reportingTerms = new ReportingTerms(this.reportingFrequency);

        return  _reportingTerms.getReportingTerms()
    }
}
